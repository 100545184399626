/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./search-box.component";
import * as i2 from "../../shared/location.service";
var styles_SearchBoxComponent = [];
var RenderType_SearchBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchBoxComponent, data: {} });
export { RenderType_SearchBoxComponent as RenderType_SearchBoxComponent };
export function View_SearchBoxComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { searchBox: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["searchBox", 1]], null, 0, "input", [["aria-label", "search"], ["placeholder", "\u641C\u7D22"], ["type", "search"]], null, [[null, "input"], [null, "keyup"], [null, "focus"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.doSearch() !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.doSearch() !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (_co.doFocus() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.doSearch() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null))], null, null); }
export function View_SearchBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-search-box", [], null, null, null, View_SearchBoxComponent_0, RenderType_SearchBoxComponent)), i0.ɵdid(1, 4243456, null, 0, i1.SearchBoxComponent, [i2.LocationService], null, null)], null, null); }
var SearchBoxComponentNgFactory = i0.ɵccf("aio-search-box", i1.SearchBoxComponent, View_SearchBoxComponent_Host_0, {}, { onSearch: "onSearch", onFocus: "onFocus" }, []);
export { SearchBoxComponentNgFactory as SearchBoxComponentNgFactory };
